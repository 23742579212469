.JSONEditor {
  min-height: 250px;
  width: 100%;
  height: 100%;
}

.JSONEditor a.jsoneditor-url {
  pointer-events: none;
}

.JSONEditor > a.jsoneditor-url {
  pointer-events: none;
}

.JSONEditor div.jsoneditor-menu {
  height: 35px;
  color: #fff;
  background-color: #2B5595;
  border-bottom: 1px solid #2B5595;
  background-color: #848484;
  border-bottom: 1px solid #848484;
  margin: 0;
  border: none;
}

.JSONEditor div.jsoneditor {
  color: #e9e9e9;
  border: thin solid #000000;
}

.jsoneditor-tree {
  background-color: rgba(245, 245, 245, 1);
}

.jsoneditor-tree {
  background-color: rgba(245, 245, 245, 1);
}

div.jsoneditor td.jsoneditor-separator {
  padding: 3px 0;
  vertical-align: top;
  color: darkgray;
  background-color: #e5e5e5;
}

div.jsoneditor td.jsoneditor-separator {
  color: magenta;
  background-color: transparent;
}